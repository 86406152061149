const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://tbz4uwxs6i.execute-api.us-east-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://s0sistigc3.execute-api.us-east-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.4',
    HOSTNAME: 'https://teams-service.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://w0ii35o49j.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;
